import { render, staticRenderFns } from "./recordListView.vue?vue&type=template&id=73d3c900&scoped=true"
import script from "./recordListView.vue?vue&type=script&lang=js"
export * from "./recordListView.vue?vue&type=script&lang=js"
import style0 from "./recordListView.vue?vue&type=style&index=0&id=73d3c900&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73d3c900",
  null
  
)

export default component.exports